import React, { useEffect, useState } from "react";
import "./Header.scss";
import { Link, Route, Routes, useLocation } from "react-router-dom";
// import plofileImg from "../assets/images/plofileImg.png";
// import Login from "../pages/Login";
import logo from "../assets/images/logo.png";
import Main from "../pages/Main";
import Wallet from "../pages/Wallet";
import Tournament from "../pages/Tournament";
import UserDetail from "../pages/UserDetail";
import UserInfo from "../pages/UserInfo";
import GameRecord from "../pages/GameRecord";
import Cookies from "js-cookie";
import HighLight from "../pages/HighLight";
import Governance from "../pages/Governance";
import Ladder from "../pages/Ladder";
import LadderCreate from "../pages/LadderCreate";
import LadderEdit from "../pages/LadderEdit";
import SuperHost from "../pages/SuperHost";
import SuperHostDetail from "../pages/SuperHostDetail";

const networks = ["Polygon", "Avalanche", "Base", "opBNB", "Arbitrum", "Xpla"];

const Header = ({ setIsLoggedIn }) => {
  const [showLogoutButton, setShowLogoutButton] = useState(false);

  // 프로필 버튼 클릭 이벤트 핸들러
  const toggleLogoutButton = () => {
    setShowLogoutButton(!showLogoutButton);
  };

  const [loginName, setLoginName] = useState("admin");
  const [mMenuOnOff, setMmenuOnOff] = useState(false);

  const handleLogout = () => {
    // 로그아웃 버튼 클릭 시 쿠키에서 토큰 삭제
    Cookies.remove("token");
    Cookies.remove("isLoggedIn");
    setIsLoggedIn(false); // 로그인 상태를 false로 설정
    window.location.href = "/";
  };
  // token이 있을때만 로그인 상태 유지, 없으면 로그인 페이지로
  useEffect(() => {
    const token = Cookies.get("token");
    if (token) {
      setIsLoggedIn(true);
    } else {
      handleLogout();
    }
  }, []);

  const handleMmenuOnOff = () => {
    setMmenuOnOff((prevMmenuOnOff) => {
      const newMmenuOnOff = !prevMmenuOnOff;
      if (newMmenuOnOff) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "";
      }
      return newMmenuOnOff;
    });
  };
  const handleMmenuBackground = (e) => {
    if (e.target === e.currentTarget) {
      console.log(e.target);
      console.log(e.currentTarget);
      setMmenuOnOff((prevMmenuOnOff) => {
        const newMmenuOnOff = !prevMmenuOnOff;
        if (newMmenuOnOff) {
          document.body.style.overflow = "hidden";
        } else {
          document.body.style.overflow = "";
        }
        return newMmenuOnOff;
      });
    }
  };

  const location = useLocation();
  // console.log("location", location.pathname.slice(1));
  const [clickMenu, setClickMenu] = useState(location.pathname.slice(1));

  return (
    <>
      <div className="header">
        <div className="header-inner">
          <h1
            onClick={() => {
              setClickMenu("");
            }}
          >
            <Link to="/Polygon">
              <img src={logo} alt="logoImg" />
            </Link>
          </h1>
          <div className="header-menu">
            <ul className="header-menu__ul">
              <li
                className={`header-menu__li  ${
                  clickMenu === "" ? "header-menu__li-clicked" : ""
                }`}
                onClick={() => {
                  setClickMenu("");
                }}
              >
                <Link to="/Polygon?page=1">
                  유저관리
                  <ul className="gnb--detail">
                    <li>
                      <Link to="/game-record">게임별 기록 조회</Link>
                    </li>
                    {networks.map((item) => (
                      <li>
                        <Link to={`/${item}?page=1`}>{item}</Link>
                      </li>
                    ))}
                  </ul>
                </Link>
              </li>
              <li
                className={`header-menu__li ${
                  clickMenu === "tournament" ? "header-menu__li-clicked" : ""
                }`}
                onClick={() => {
                  setClickMenu("tournament");
                }}
              >
                <Link to="/tournament/Polygon?page=1">
                  토너먼트 관리
                  <ul className="gnb--detail">
                    {networks.map((item) => (
                      <li>
                        <Link to={`/tournament/${item}?page=1`}>{item}</Link>
                      </li>
                    ))}
                  </ul>
                </Link>
              </li>
              <li
                className={`header-menu__li ${
                  clickMenu === "wallet" ? "header-menu__li-clicked" : ""
                } `}
                onClick={() => {
                  setClickMenu("wallet");
                }}
              >
                <Link to="/wallet/Polygon">
                  지갑 관리
                  <ul className="gnb--detail">
                    {networks.map((item) => (
                      <li>
                        <Link to={`/wallet/${item}`}>{item}</Link>
                      </li>
                    ))}
                  </ul>
                </Link>
              </li>
              {/* =======================================하이라이트 관리 START====================================== */}
              <li
                className={`header-menu__li ${
                  clickMenu === "high-light" ? "header-menu__li-clicked" : ""
                } `}
                onClick={() => {
                  setClickMenu("high-light");
                }}
              >
                <Link to="/high-light/Polygon">
                  하이라이트 관리
                  <ul className="gnb--detail">
                    <li>
                      <Link to="/high-light/Intro">INTRO</Link>
                    </li>
                    {networks.map((item) => (
                      <li>
                        <Link to={`/high-light/${item}`}>{item}</Link>
                      </li>
                    ))}
                  </ul>
                </Link>
              </li>
              {/* =======================================하이라이트 관리 END====================================== */}
              {/* =======================================거버넌스 관리 START====================================== */}
              <li
                className={`header-menu__li ${
                  clickMenu === "governance" ? "header-menu__li-clicked" : ""
                } `}
                onClick={() => {
                  setClickMenu("governance");
                }}
              >
                <Link to="/governance">거버넌스 관리</Link>
              </li>
              {/* =======================================거버넌스 관리 END====================================== */}
              {/* =======================================레더시즌 관리 START====================================== */}

              <li
                className={`header-menu__li ${
                  clickMenu === "ladder" ? "header-menu__li-clicked" : ""
                }`}
                onClick={() => {
                  setClickMenu("ladder");
                }}
              >
                <Link to="/ladder/Polygon?page=1">
                  레더시즌관리
                  <ul className="gnb--detail">
                    {networks.map((item) => (
                      <li>
                        <Link to={`/ladder/${item}?page=1`}>{item}</Link>
                      </li>
                    ))}
                  </ul>
                </Link>
              </li>
              <li
                className={`header-menu__li ${
                  clickMenu === "superHost" ? "header-menu__li-clicked" : ""
                }`}
                onClick={() => {
                  setClickMenu("superHost");
                }}
              >
                <Link to="/super-host?page=1">
                  슈퍼 호스트 관리
                  {/* <ul className="gnb--detail">
                    {networks.map((item) => (
                      <li>
                        <Link to={`/super-host/${item}?page=1`}>{item}</Link>
                      </li>
                    ))}
                  </ul> */}
                </Link>
              </li>

              {/* =======================================레더시즌 관리 END====================================== */}
            </ul>
          </div>
          {/* <div className="header-menu__username">{loginName},로그인 </div> */}
          {/* <div className="header-menu__username">{loginName} 로그아웃 </div> */}
          <div className="header-menu__username">
            <div className="user--info">
              <p>본사 • 최고관리자</p>
              <span>{loginName}</span>
            </div>
            <div className="user--my--box">
              <button
                type="button"
                className="profile--btn"
                onClick={toggleLogoutButton}
              ></button>
              {showLogoutButton && (
                <button className="logout--btn" onClick={handleLogout}>
                  로그아웃
                </button>
              )}
            </div>
          </div>
          {/* 반응형시 나오는 버튼 */}
          <div
            className={mMenuOnOff ? "m-main-menu active" : "m-main-menu"}
            onClick={handleMmenuOnOff}
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
          {/* 버튼클릭시 호출되는 메뉴들 */}
          {/* 모바일 메뉴 시작 */}
          {mMenuOnOff ? (
            <div className="header__mobile-menu">
              <p className="mobile-menu__login-status">{loginName} 로그인중</p>
              <ul className="mobile-menu__list">
                <li
                  className={`mobile-menu__li  ${
                    clickMenu === "" ? "mobile-menu__li-clicked" : ""
                  }`}
                  onClick={() => {
                    setClickMenu("");
                    handleMmenuOnOff(); // 메뉴 항목 클릭 시 메뉴 닫기
                  }}
                >
                  <Link to="/Polygon?page=1">
                    유저관리
                    <ul className="gnb--detail">
                      <li>
                        <Link to="/game-record">게임별 기록 조회</Link>
                      </li>
                      {networks.map((item) => (
                        <li>
                          <Link to={`/${item}?page=1`}>{item}</Link>
                        </li>
                      ))}
                    </ul>
                  </Link>
                </li>
                <li
                  className={`mobile-menu__li ${
                    clickMenu === "tournament" ? "mobile-menu__li-clicked" : ""
                  } `}
                  onClick={() => {
                    setClickMenu("tournament");
                    handleMmenuOnOff(); // 메뉴 항목 클릭 시 메뉴 닫기
                  }}
                >
                  <Link to="/tournament/Polygon">
                    토너먼트 관리
                    <ul className="gnb--detail">
                      {networks.map((item) => (
                        <li>
                          <Link to={`/tournament/${item}?page=1`}>{item}</Link>
                        </li>
                      ))}
                    </ul>
                  </Link>
                </li>
                <li
                  className={`mobile-menu__li ${
                    clickMenu === "wallet" ? "mobile-menu__li-clicked" : ""
                  } `}
                  onClick={() => {
                    setClickMenu("wallet");
                    handleMmenuOnOff(); // 메뉴 항목 클릭 시 메뉴 닫기
                  }}
                >
                  <Link to="/wallet/Polygon">
                    지갑관리
                    <ul className="gnb--detail">
                      {networks.map((item) => (
                        <li>
                          <Link to={`/wallet/${item}`}>{item}</Link>
                        </li>
                      ))}
                    </ul>
                  </Link>
                </li>
                {/* =======================================하이라이트 관리 START====================================== */}
                <li
                  className={`mobile-menu__li ${
                    clickMenu === "high-light" ? "mobile-menu__li-clicked" : ""
                  }`}
                  onClick={() => {
                    setClickMenu("high-light");
                    handleMmenuOnOff(); // 메뉴 항목 클릭 시 메뉴 닫기
                  }}
                >
                  <Link to="/high-light/Polygon">
                    하이라이트 관리
                    <ul className="gnb--detail">
                      <li>
                        <Link to="/high-light/Intro">INTRO</Link>
                      </li>
                      {networks.map((item) => (
                        <li>
                          <Link to={`/high-light/${item}`}>{item}</Link>
                        </li>
                      ))}
                    </ul>
                  </Link>
                </li>
                {/* =======================================하이라이트 관리 END====================================== */}
                {/* =======================================거버넌스 관리 START====================================== */}
                {/* =======================================거버넌스 관리 START====================================== */}
                <li
                  className={`mobile-menu__li ${
                    clickMenu === "governance" ? "mobile-menu__li-clicked" : ""
                  }`}
                  onClick={() => {
                    setClickMenu("governance");
                    handleMmenuOnOff(); // 메뉴 항목 클릭 시 메뉴 닫기
                  }}
                >
                  <Link to="/governance">거버넌스 관리</Link>
                </li>
                {/* ====================거버넌스 관리 END====================================== */}

                {/* =======================================레더시즌 관리 START====================================== */}
                <li
                  className={`mobile-menu__li ${
                    clickMenu === "ladder" ? "mobile-menu__li-clicked" : ""
                  }`}
                  onClick={() => {
                    setClickMenu("ladder");
                    handleMmenuOnOff(); // 메뉴 항목 클릭 시 메뉴 닫기
                  }}
                >
                  <Link to="/ladder/Polygon">
                    레더시즌 관리
                    <ul className="gnb--detail">
                      {networks.map((item) => (
                        <li>
                          <Link to={`/ladder/${item}`}>{item}</Link>
                        </li>
                      ))}
                    </ul>
                  </Link>
                </li>
                {/* =======================================레더시즌 관리 END====================================== */}
                <li
                  className={`mobile-menu__li ${
                    clickMenu === "superHost" ? "header-menu__li-clicked" : ""
                  }`}
                  onClick={() => {
                    setClickMenu("superHost");
                    handleMmenuOnOff(); // 메뉴 항목 클릭 시 메뉴 닫기
                  }}
                >
                  <Link to="/super-host/Polygon?page=1">
                    슈퍼 호스트 관리
                    <ul className="gnb--detail">
                      {networks.map((item) => (
                        <li>
                          <Link to={`/super-host/${item}?page=1`}>{item}</Link>
                        </li>
                      ))}
                    </ul>
                  </Link>
                </li>
              </ul>
              <button className="logout--btn" onClick={handleLogout}>
                로그아웃
              </button>
            </div>
          ) : null}
        </div>
        <div
          className={
            mMenuOnOff
              ? "m-main-menu__background active-menu"
              : "m-main-menu__background"
          }
          onClick={handleMmenuBackground}
        ></div>
      </div>

      <Routes>
        <Route
          path="/:network"
          element={<Main setClickMenu={setClickMenu} />}
        />
        <Route path="/tournament/:network" element={<Tournament />} />
        <Route path="/wallet/:network" element={<Wallet />} />
        <Route path="/high-light/:network" element={<HighLight />} />
        <Route path="/user-detail/:id" element={<UserDetail />} />
        <Route
          path="/user-information"
          element={<UserInfo render="normal" />}
        />
        <Route path="/game-record" element={<GameRecord />} />
        <Route path="/governance" element={<Governance />} />
        <Route path="/ladder/:network" element={<Ladder />} />
        <Route path="/ladder-create/:network" element={<LadderCreate />} />
        <Route
          path="/ladder-edit/:network/:season_id"
          element={<LadderEdit />}
        />
        <Route path="/super-host/" element={<SuperHost />} />
        <Route
          path="/super-host-detail/:host_id"
          element={<SuperHostDetail />}
        />
      </Routes>
    </>
  );
};

export default Header;
