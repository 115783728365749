import "./LadderSettings.scss";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

// 이미지
import searchIcon from "../assets/images/searchIcon.png";
import checkImg from "../assets/images/check.png";
import upDateImg from "../assets/images/add_a_photo.svg";
import deleteIcon from "../assets/images/delete_forever.svg";
import addBtnIcon from "../assets/images/add_circle.svg";

import DeleteModal from "../components/DeleteModal";
import TokenType from "../components/TokenType";
import GovernanceCancelModal from "../components/GovernanceCancelModal";

import { formatUtcTime, formatLocaleTime } from "../utils/formatDate";
import { previewImage } from "../utils/previewImage";
import Cookies from "js-cookie";
import LadderCreateModal from "./LadderCreateModal";

let trigger = 0;

function LadderSettings({
  ladderSeasonData,
  ladderPageImgData,
  dailyListData,
  experienceListData,
  penaltyListData,
  ladderRankData,
  type,
}) {
  const token = Cookies.get("token");
  const serverApi = process.env.REACT_APP_AWS_API;
  const { network } = useParams();

  // 미션 리스트
  const [dailyMissionList, setDailyMissionList] = useState([]);
  const [expMissionList, setExpMissionList] = useState([]);
  const [penaltyMissionList, setPenaltyMissionList] = useState([]);

  // 드롭다운
  const [dropdownOpen, setDropdownOpen] = useState(null);

  // 레더시즌 데이터
  const [ladderSeason, setLadderSeason] = useState(ladderSeasonData);
  const [ladderPageImg, setLadderPageImg] = useState(ladderPageImgData);
  const [dailyList, setDailyList] = useState(dailyListData);
  const [experienceList, setExperienceList] = useState(experienceListData);
  const [penaltyList, setPenaltyList] = useState(penaltyListData);
  const [ladderRank, setLadderRank] = useState(ladderRankData);

  const disable = type === "edit";

  // 에러 객체
  const [validateObj, setValidateObj] = useState({
    title: true,
    start_date: true,
    end_date: true,
    description: true,
    page_img: true,
    exp_list: true,
    daily_list: true,
    experience_list: true,
    penalty_list: true,
  });

  // 모달
  const [modal, setModal] = useState(false);

  // 타이틀, 날짜, 설명 셋팅
  const ladderSeasonSetting = (e) => {
    const copy = { ...ladderSeason };
    copy[e.target.name] = e.target.value;
    setLadderSeason(copy);
  };
  const setArraySetter = (type) => {
    let originArray;
    let setter;
    let length;
    switch (type) {
      case "daily":
        originArray = dailyList;
        setter = setDailyList;
        length = dailyMissionList.length; // 미션의 총 개수
        break;
      case "exp":
        originArray = experienceList;
        setter = setExperienceList;
        length = expMissionList.length; // 미션의 총 개수
        break;
      case "penalty":
        originArray = penaltyList;
        setter = setPenaltyList;
        length = penaltyMissionList.length; // 미션의 총 개수
        break;
    }
    let copy = [...originArray];
    return [copy, setter, length];
  };

  // 미션(데일리, 익스피리언스, 패널티) 관리
  const missionListSetting = (type, mission, index) => {
    /**
     * 미션 리스트 유형 설정
     */
    const [copy, setter] = setArraySetter(type);
    copy[index] = {
      exp_value: 0,
      experience_penalty_id: mission.id,
      title: mission.title,
    };
    setter(copy);
  };
  const missionRewardSetting = (type, e, index) => {
    /**
     * 미션 리스트 보상 설정
     */
    const [copy, setter] = setArraySetter(type);
    copy[index] = {
      ...copy[index],
      exp_value:
        type === "penalty" ? -Math.abs(e.target.value) : e.target.value,
    };
    setter(copy);
  };

  const addList = (type) => {
    /**
     * 미션 리스트 추가
     */
    const [copy, setter, length] = setArraySetter(type);

    if (copy.length >= length) return; // 서버에서 가져온 미션의 개수와 현재 미션 선택 배열의 length가 크거나 같은 경우 추가가 불가능.

    copy.push({
      exp_value: 0,
      experience_penalty_id: 0,
    });
    setter(copy);
    setDropdownOpen(null);
  };

  const deleteList = (type, index) => {
    /**
     * 미션 리스트 삭제
     */
    const [copy, setter] = setArraySetter(type);
    copy.splice(index, 1);
    setter(copy);
    setDropdownOpen(null);
  };

  // 래더 랭크 설정
  const ladderRankSetting = (e, index) => {
    const copy = [...ladderRank];
    copy[index][e.target.name] = isNaN(parseInt(e.target.value))
      ? e.target.value
      : parseInt(e.target.value);
    setLadderRank(copy);
    // 입력 감지 : 랭크 데이터 경험치 및 레벨 자동 계산.
    if (trigger === 1000) {
      trigger = 0;
    } else {
      trigger++;
    }
  };

  const ladderRankImageSetting = (e, index) => {
    const copy = [...ladderRank];
    copy[index][e.target.name] = e.target.files[0];
    setLadderRank(copy);
  };

  //================
  // 미션 리스트 GET
  //================
  const getMissionList = async (category) => {
    const res = await axios.get(
      `${serverApi}/api/cms/ladder/season/experience/penalty?network=${network}&category=${category}`
    );
    return res.data;
  };

  //====================
  // 유효성 검사.
  //====================
  const validate = () => {
    const { title, description, start_date, end_date } = ladderSeason;

    let daily_list = dailyList.every(
      (item) =>
        item.experience_penalty_id &&
        item.exp_value !== 0 &&
        item.exp_value &&
        item.title
    );

    let experience_list = experienceList.every(
      (item) =>
        item.experience_penalty_id &&
        item.exp_value !== 0 &&
        item.exp_value &&
        item.title
    );

    let penalty_list = penaltyList.every(
      (item) =>
        item.experience_penalty_id &&
        item.exp_value !== 0 &&
        item.exp_value &&
        item.title
    );

    let exp_list = ladderRank.every((item) => {
      let result = Object.entries(item).every(([key, value]) => {
        if (value === null || value < 0 || value === "") {
          return false;
        } else {
          return true;
        }
      });
      return result;
    });

    const obj = {
      title: title.trim(),
      start_date,
      end_date,
      description: description.trim(),
      page_img: ladderPageImg,
      exp_list,
      daily_list,
      experience_list,
      penalty_list,
    };

    const validate = Object.entries(obj).find(([key, value]) => !value);

    console.log(validate);

    if (validate) {
      let errorTag = document.querySelector(`#${validate[0]}`);
      errorTag?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      setValidateObj(obj);
      return;
    }

    setModal(true);
  };

  //====================
  // 서버 요청
  //====================
  const request = async () => {
    try {
      const formData = new FormData();

      const {
        title,
        description,
        start_date,
        end_date,
        network,
        id: ladder_id,
      } = ladderSeason;

      // 필요없는 필드 삭제
      dailyList.forEach((item) => {
        delete item.title;
      });
      experienceList.forEach((item) => {
        delete item.title;
      });
      penaltyList.forEach((item) => {
        delete item.title;
      });

      let payload = {
        ladder_season: { title, description, start_date, end_date, network },
        daily_list: dailyList,
        experience_list: experienceList,
        penalty_list: penaltyList,
        exp_list: ladderRank,
      };

      // 이미지 append
      ladderRank?.forEach((item, index) => {
        if (typeof item.tier_image === "object") {
          // 파일일 때 (새로운 이미지를 사용할 때)
          formData.append(`tier_image${index + 1}`, item.tier_image);
        } else {
          // 파일이 아닐 때 (기존 이미지를 사용할 때)
          formData.append(`tier_image${index + 1}`, null);
        }
        delete item.tier_image;
      });

      formData.append("page_img", ladderPageImg);
      formData.append("payload", JSON.stringify(payload));

      let url;
      if (type === "create") {
        url = "api/cms/ladder/season";
      } else if (type === "edit") {
        url = `api/cms/ladder/season/${ladder_id}`;
      }

      console.log(payload, "페이로드");

      const res = await axios.post(`${serverApi}/${url}`, formData, {
        headers: { Authorization: `Bearer ${token}` },
        "Content-Type": "multipart/form-data", // 파일 업로드용 헤더 설정
      });
    } catch (e) {
      throw new Error(e);
    }
  };

  useEffect(() => {
    let STO;
    STO = setTimeout(() => {
      let copy = [...ladderRank];
      for (let i = 0; i < 5; i++) {
        let now = copy[i];
        let next = copy[i + 1];

        if (i === 0) {
          // 최대 경험치 구간 계산 index가 0경우
          now.max_exp = now.max_level * now.required_exp;
        } else {
          // 최대 경험치 구간 계산 index가 0이 아닌 경우
          now.max_exp =
            now.required_exp === 0
              ? 0
              : (now.max_level - now.min_level + 1) * now.required_exp +
                now.min_exp -
                1;
        }
        if (i !== 4) {
          // 다음 랭크의 최소 레벨 및 최소 경험치 산정 index가 마지막 index인 경우 다음 랭크가 존재하지 않으므로 X
          next.min_level = now.max_level === 0 ? 0 : now.max_level + 1;
          next.min_exp = now.max_exp === 0 ? 0 : now.max_exp + 1;
        }
      }
      setLadderRank(copy);
    }, 500);
    return () => {
      clearTimeout(STO);
    };
    // trigger 변수의 경우 랭크 데이터의 입력 감지 용도로만 사용됨
  }, [trigger]);

  useEffect(() => {
    Promise.all([
      getMissionList("daily"),
      getMissionList("experience"),
      getMissionList("penalty"),
    ])
      .then(([daily, exp, penalty]) => {
        setDailyMissionList(daily);
        setExpMissionList(exp);
        setPenaltyMissionList(penalty);
      })
      .catch((e) => {
        console.error(e);
      });
  }, [network]);

  return (
    <>
      <div className="main">
        <div className="main--inner">
          <div className="header--box">
            <div className="main--title">
              레더시즌 {type === "create" ? "생성" : "수정"}
              <TokenType tokenType={network} />
            </div>
          </div>
          {/* header--box --END--*/}

          <div className="ladder-create">
            <dl className="ladder-create__input">
              <dt>레더시즌 타이틀</dt>
              <dd>
                <input
                  placeholder="타이틀을 입력해주세요"
                  name="title"
                  id="title"
                  value={ladderSeason?.title}
                  onChange={(e) => ladderSeasonSetting(e)}
                />
                {!validateObj?.title && (
                  <p className="err">레더 시즌 타이틀명을 작성해주세요</p>
                )}
              </dd>
            </dl>
            <dl className="ladder-create__input two">
              <dt>레더시즌 기간</dt>
              <div className="ladder-create__input__cover">
                <dd>
                  <input
                    placeholder="-"
                    type="datetime-local"
                    name="start_date"
                    id="start_date"
                    value={ladderSeason?.start_date}
                    onChange={(e) => ladderSeasonSetting(e)}
                    disabled={disable}
                  />
                  {ladderSeason?.start_date && (
                    <p className="utc-box">
                      {formatUtcTime(ladderSeason?.start_date)}
                      <span>{formatLocaleTime(ladderSeason?.start_date)}</span>
                    </p>
                  )}
                  {!validateObj?.start_date && (
                    <p className="err">
                      올바른 레더 시즌의 시작 날짜를 선택해주세요
                    </p>
                  )}
                </dd>
                <dd>
                  <input
                    placeholder="-"
                    type="datetime-local"
                    name="end_date"
                    id="end_date"
                    value={ladderSeason?.end_date}
                    onChange={(e) => ladderSeasonSetting(e)}
                    disabled={disable}
                  />
                  {ladderSeason?.end_date && (
                    <p className="utc-box">
                      {formatUtcTime(ladderSeason?.end_date)}
                      <span>{formatLocaleTime(ladderSeason?.end_date)}</span>
                    </p>
                  )}
                  {!validateObj?.end_date && (
                    <p className="err">
                      올바른 레더 시즌의 종료 날짜를 선택해주세요
                    </p>
                  )}
                </dd>
              </div>
            </dl>
            <dl className="ladder-create__textarea">
              <dt>레더시즌 설명 (200자 이하)</dt>
              <dd>
                <textarea
                  placeholder="Enter here"
                  name="description"
                  id="description"
                  value={ladderSeason?.description}
                  maxLength={200}
                  onChange={(e) => ladderSeasonSetting(e)}
                />
                {!validateObj?.description && (
                  <p className="err">레더 시즌의 설명을 작성해주세요</p>
                )}
              </dd>
            </dl>
            <dl className="ladder-create__img" id="page_img">
              <dt>레더시즌 페이지 이미지</dt>
              <dd>
                <div className="image-upload">
                  <label className="upload-label">
                    {ladderPageImg ? (
                      <Preview image={ladderPageImg} />
                    ) : (
                      <span>이미지 업로드</span>
                    )}
                    <input
                      type="file"
                      onChange={(e) => setLadderPageImg(e.target.files[0])}
                      accept="image/*"
                    />
                  </label>
                </div>
                <p className="small-txt">최대 1920px X 2000px, 30MB 이하</p>
                {!validateObj?.page_img && (
                  <p className="err">이미지를 업로드 해주세요</p>
                )}
              </dd>
            </dl>
            <dl className="ladder-create__settings">
              <dt>레더시즌 세팅</dt>
              <dd>
                <div className="ranking-table">
                  <table>
                    <thead>
                      <tr>
                        <th>랭킹</th>
                        <th>레더 심볼 로고</th>
                        <th>레더 (오름차순)</th>
                        <th>레벨 구간 (최소)</th>
                        <th>레벨 구간 (최대)</th>
                        <th>경험치 구간 (최소)</th>
                        <th>경험치 구간 (최대)</th>
                        <th>레벨업에 필요한 경험치</th>
                      </tr>
                    </thead>
                    <tbody id="exp_list">
                      {ladderRank?.map((row, index, { length }) => (
                        <tr key={index}>
                          <td>
                            {length - index === 1 && length - index + "st"}
                            {length - index === 2 && length - index + "nd"}
                            {length - index === 3 && length - index + "rd"}
                            {length - index > 3 && length - index + "th"}
                          </td>
                          <td>
                            <div className="upload-container">
                              {/* <img src={logos[index]} alt={`${row.rank} logo`} className="logo-preview" /> */}
                              {!row.tier_image ? (
                                <img
                                  src={upDateImg}
                                  alt={`${row.rank} logo`}
                                  className="logo-preview"
                                />
                              ) : (
                                <Preview
                                  image={row.tier_image}
                                  className="logo-preview"
                                />
                              )}
                              {!disable && (
                                <label className="upload-button">
                                  변경
                                  <input
                                    type="file"
                                    name="tier_image"
                                    accept="image/*"
                                    onChange={(e) => {
                                      ladderRankImageSetting(e, index);
                                    }}
                                    disabled={disable}
                                  />
                                </label>
                              )}
                              <div className="placeholder">
                                40px x 40px, 3MB 이하
                              </div>
                            </div>
                          </td>
                          <td>
                            <input
                              placeholder={row.tier_name}
                              value={row.tier_name}
                              name="tier_name"
                              onChange={(e) => ladderRankSetting(e, index)}
                              disabled={disable}
                            />
                          </td>
                          <td>
                            <input
                              placeholder={row.min_level}
                              value={row.min_level}
                              type="number"
                              name="min_level"
                              onWheel={(e) => e.target.blur()}
                              onChange={(e) => ladderRankSetting(e, index)}
                              disabled
                            />
                          </td>
                          <td>
                            <input
                              placeholder={row.max_level}
                              value={row.max_level}
                              type="number"
                              name="max_level"
                              onWheel={(e) => e.target.blur()}
                              onChange={(e) => ladderRankSetting(e, index)}
                              disabled={disable}
                            />
                          </td>
                          <td>
                            <input
                              placeholder={row.min_exp}
                              value={row.min_exp}
                              type="number"
                              name="min_exp"
                              onWheel={(e) => e.target.blur()}
                              onChange={(e) => ladderRankSetting(e, index)}
                              disabled
                            />
                          </td>
                          <td>
                            <input
                              placeholder={row.max_exp}
                              value={row.max_exp}
                              type="number"
                              name="max_exp"
                              onWheel={(e) => e.target.blur()}
                              onChange={(e) => ladderRankSetting(e, index)}
                              disabled
                            />
                          </td>
                          <td>
                            <input
                              placeholder={row.required_exp}
                              value={row.required_exp}
                              type="number"
                              name="required_exp"
                              onWheel={(e) => e.target.blur()}
                              onChange={(e) => ladderRankSetting(e, index)}
                              disabled={disable}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                {!validateObj?.exp_list && (
                  <p className="err">올바른 래더 리스트를 작성해주세요</p>
                )}
              </dd>
            </dl>
            <dl className="ladder-create__missions" id="daily_list">
              <dt>데일리 미션 세팅</dt>
              <dd>
                <div className="daily-missions-table">
                  <table>
                    <thead>
                      <tr>
                        <th>데일리 미션</th>
                        <th>경험치</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {dailyList?.map((row, index) => (
                        <tr key={index}>
                          <td>
                            <div
                              className={`select-box ${
                                dropdownOpen === `daily_${index}`
                                  ? "active"
                                  : ""
                              }`}
                            >
                              <p
                                className="select-box__title"
                                onClick={() => {
                                  if (disable) {
                                    return;
                                  } else if (
                                    dropdownOpen === `daily_${index}`
                                  ) {
                                    setDropdownOpen(null);
                                    return;
                                  }
                                  setDropdownOpen(`daily_${index}`);
                                }}
                              >
                                {row.title ? row.title : "선택"}
                              </p>
                              <ul className="select-box__list">
                                {dailyMissionList?.map((mission, i) => (
                                  <li
                                    key={i}
                                    className="select-box__list__item"
                                    onClick={() => {
                                      missionListSetting(
                                        "daily",
                                        mission,
                                        index
                                      );
                                      setDropdownOpen(null);
                                    }}
                                  >
                                    {mission.title}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </td>
                          <td>
                            <input
                              type="number"
                              onWheel={(e) => e.target.blur()}
                              value={row.exp_value}
                              onChange={(e) =>
                                missionRewardSetting("daily", e, index)
                              }
                              disabled={disable}
                            />
                          </td>
                          <td>
                            {!disable && (
                              <button
                                onClick={() => deleteList("daily", index)}
                                className="delete-button"
                              >
                                <img src={deleteIcon} />
                              </button>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                {!disable && (
                  <button
                    onClick={() => addList("daily")}
                    className="add-button"
                  >
                    <img src={addBtnIcon} /> 추가
                  </button>
                )}
              </dd>
            </dl>
            {!validateObj?.daily_list && (
              <p className="err">올바른 데일리 미션 정보를 작성해주세요</p>
            )}
            <dl className="ladder-create__EXP" id="experience_list">
              <dt>경험치 세팅</dt>
              <dd>
                <div className="exp-settings-table">
                  <table>
                    <thead>
                      <tr>
                        <th>EXP 조건</th>
                        <th>경험치</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {experienceList?.map((row, index) => (
                        <tr key={index}>
                          <td>
                            <div
                              className={`select-box ${
                                dropdownOpen === `exp_${index}` ? "active" : ""
                              }`}
                            >
                              <p
                                className="select-box__title"
                                onClick={() => {
                                  if (disable) {
                                    return;
                                  } else if (dropdownOpen === `exp_${index}`) {
                                    setDropdownOpen(null);
                                    return;
                                  }
                                  setDropdownOpen(`exp_${index}`);
                                }}
                              >
                                {row.title ? row.title : "선택"}
                              </p>
                              <ul className="select-box__list">
                                {expMissionList?.map((mission, i) => (
                                  <li
                                    key={i}
                                    className="select-box__list__item"
                                    onClick={() => {
                                      missionListSetting("exp", mission, index);
                                      setDropdownOpen(null);
                                    }}
                                  >
                                    {mission.title}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </td>
                          <td>
                            <input
                              type="number"
                              onWheel={(e) => e.target.blur()}
                              value={row.exp_value}
                              onChange={(e) =>
                                missionRewardSetting("exp", e, index)
                              }
                              disabled={disable}
                            />
                          </td>
                          <td>
                            {!disable && (
                              <button
                                onClick={() => deleteList("exp", index)}
                                className="delete-button"
                              >
                                <img src={deleteIcon} alt="Delete" />
                              </button>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                {!disable && (
                  <button onClick={() => addList("exp")} className="add-button">
                    <img src={addBtnIcon} alt="Add" />
                    추가
                  </button>
                )}
              </dd>
            </dl>
            {!validateObj?.experience_list && (
              <p className="err">올바른 경험치 습득 조건을 작성해주세요</p>
            )}

            <dl className="ladder-create__penalty-EXP" id="penalty_list">
              <dt>페널티 세팅</dt>
              <dd>
                <div className="penalty-exp-settings-table">
                  <table>
                    <thead>
                      <tr>
                        <th>페널티 조건</th>
                        <th>감소 경험치</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {penaltyList?.map((row, index) => (
                        <tr key={index}>
                          <td>
                            <div
                              className={`select-box ${
                                dropdownOpen === `penalty_${index}`
                                  ? "active"
                                  : ""
                              }`}
                            >
                              <p
                                className="select-box__title"
                                onClick={() => {
                                  if (disable) {
                                    return;
                                  } else if (
                                    dropdownOpen === `penalty_${index}`
                                  ) {
                                    setDropdownOpen(null);
                                    return;
                                  }
                                  setDropdownOpen(`penalty_${index}`);
                                }}
                              >
                                {row.title ? row.title : "선택"}
                              </p>
                              <ul className="select-box__list">
                                {penaltyMissionList?.map((mission, i) => (
                                  <li
                                    key={i}
                                    className="select-box__list__item"
                                    onClick={() => {
                                      missionListSetting(
                                        "penalty",
                                        mission,
                                        index
                                      );
                                      setDropdownOpen(null);
                                    }}
                                  >
                                    {mission.title}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </td>
                          <td>
                            <input
                              type="number"
                              onWheel={(e) => e.target.blur()}
                              max={0}
                              value={row.exp_value}
                              onChange={(e) =>
                                missionRewardSetting("penalty", e, index)
                              }
                              placeholder="- EXP"
                              disabled={disable}
                            />
                          </td>
                          <td>
                            {!disable && (
                              <button
                                onClick={() => deleteList("penalty", index)}
                                className="delete-button"
                              >
                                <img src={deleteIcon} alt="Delete" />
                              </button>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                {!disable && (
                  <button
                    onClick={() => addList("penalty")}
                    className="add-button"
                  >
                    <img src={addBtnIcon} alt="Add" />
                    추가
                  </button>
                )}
              </dd>
            </dl>
            {!validateObj?.penalty_list && (
              <p className="err">올바른 패널티 조건을 작성해주세요</p>
            )}
            <button
              className="ladder-create__save-btn"
              onClick={() => {
                validate();
              }}
            >
              저장
            </button>
          </div>
        </div>
      </div>
      {modal && (
        <LadderCreateModal
          setModal={setModal}
          request={request}
          type={type}
          network={network}
        />
      )}
    </>
  );
}

export default LadderSettings;

const Preview = React.memo(({ image, className }) => {
  return <img src={previewImage(image)} alt="image" className={className} />;
});
