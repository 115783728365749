import axios from "axios";
import LadderSettings from "../components/LadderSettings";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { useParams } from "react-router-dom";

const serverApi = process.env.REACT_APP_AWS_API;
const token = Cookies.get("token");

const LadderEdit = () => {
  const [seasonData, setSeasonData] = useState(null);

  const { season_id } = useParams();

  const { ladder_season, daily_list, experience_list, penalty_list, exp_list } =
    seasonData || {};

  const getSeasonData = async () => {
    try {
      const res = await axios.get(
        `${serverApi}/api/cms/ladder/season/${season_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setSeasonData(res.data);
      console.log("getSeasonData", res.data);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getSeasonData();
  }, []);

  if (seasonData === null) return;

  return (
    <LadderSettings
      ladderSeasonData={ladder_season}
      ladderPageImgData={ladder_season?.page_img}
      dailyListData={daily_list}
      experienceListData={experience_list}
      penaltyListData={penalty_list}
      ladderRankData={exp_list}
      type={"edit"}
    />
  );
};

export default LadderEdit;
