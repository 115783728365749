import React, { useEffect, useState } from "react";
import "./LadderCreateModal.scss";
import LoadingComponent from "./LoadingComponent";
import { useNavigate } from "react-router-dom";

const LadderCreateModal = ({ setModal, request, type, network }) => {
  const [pageStatus, setPageStatus] = useState(null);
  const navigate = useNavigate();

  const process = async () => {
    try {
      setPageStatus("Loading");
      await request();
      navigate(`/ladder/${network}?page=1`);
    } catch (e) {
      console.error(e);
      setPageStatus("Error");
    }
  };

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "";
    };
  }, []);

  return (
    <>
      <div className="ladder-create-modal">
        <div className="ladder-create-modal__title">
          {pageStatus !== "Error" &&
            `레더시즌을 ${type === "create" ? "생성" : "수정"} 하시겠습니까?`}
          {pageStatus === "Error" && "에러가 발생하였습니다."}
        </div>
        <div className="ladder-create-modal__btn-box">
          <button
            className="modal__cancel-btn"
            onClick={() => {
              if (pageStatus === "Loading") {
                return;
              }
              setModal(false);
            }}
          >
            취소
          </button>
          <button
            className="modal__confirm-btn"
            onClick={() =>
              pageStatus === null
                ? process()
                : pageStatus === "Error"
                ? setPageStatus(null)
                : null
            }
            disabled={pageStatus === "Loading"}
          >
            {pageStatus === null && <>확인</>}
            {pageStatus === "Loading" && <LoadingComponent />}
            {pageStatus === "Error" && <>재시도</>}
          </button>
        </div>
      </div>
      <div
        className="link-modal__background"
        onClick={() => {
          if (pageStatus === "Loading") {
            return;
          }
          setModal(false);
        }}
      ></div>
    </>
  );
};

export default LadderCreateModal;
