import "../styles/SuperHost.scss";

import axios from "axios";

import { useState, useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { Link } from "react-router-dom";

import { formatLocaleTime, formatUtcTime } from "../utils/formatDate";
import TokenType from "../components/TokenType";

import searchIcon from "../assets/images/searchIcon.png";
import Cookies from "js-cookie";

const serverApi = process.env.REACT_APP_AWS_API;

const SuperHost = () => {
  const token = Cookies.get("token");
  const [queryParam, setQueryParam] = useSearchParams();
  const { network } = useParams();
  const page = queryParam.get("page");
  const search_keyword = queryParam.get("search_keyword");

  const pagesPerBlock = 5;
  const [superHostList, setSuperHostList] = useState([]);
  const [pageNumbers, setPageNumbers] = useState([]);
  const [totalPages, setTotalPages] = useState(0);

  const getSuperHostList = async (page = 1, search_keyword = "") => {
    try {
      const res = await axios.get(
        `${serverApi}/api/cms/super/host/list?page=${page}&search_keyword=${search_keyword}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setTotalPages(Math.ceil(res.data.total_cnt / 20));
      setSuperHostList(res.data.data_list);
    } catch (e) {
      console.error(e);
    }
  };

  const handlePageClick = (page) => {
    setQueryParam({ page: page, search_keyword: search_keyword || "" });
  };

  useEffect(() => {
    const startPage =
      Math.floor((page - 1) / pagesPerBlock) * pagesPerBlock + 1;
    const newPageNumbers = Array.from(
      { length: pagesPerBlock },
      (_, i) => startPage + i
    ).filter((page) => page <= totalPages);
    setPageNumbers(newPageNumbers);
  }, [page, totalPages]);

  useEffect(() => {
    getSuperHostList(page, search_keyword || "");
  }, [page, search_keyword]);

  return (
    <div className="main super-host">
      <section className="super-host__header">
        <div className="super-host__header--title-box">
          <h1 className="super-host__header--text">슈퍼 호스트 관리</h1>
          <TokenType tokenType={network} />
        </div>
        <div className="super-host__header--input-box">
          <button>
            <img src={searchIcon} />
          </button>
          <input
            className="super-host__header--input"
            placeholder="Type here..."
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                setQueryParam({ page: 1, search_keyword: e.target.value });
              }
            }}
          />
        </div>
      </section>
      <section className="super-host__list--box">
        <table>
          <thead>
            <th>번호</th>
            <th>등록일</th>
            <th>닉네임</th>
            <th>지갑주소</th>
            <th>네트워크</th>
            <th>구분</th>
            <th>상세</th>
          </thead>
          <tbody>
            {superHostList.map((item) => {
              return (
                <tr key={item.id}>
                  <td>{item.id}</td>
                  <td>
                    <div>{formatUtcTime(item.create_dt)}</div>
                    <div>{formatLocaleTime(item.create_dt)}</div>
                  </td>
                  <td>{item.name}</td>
                  <td>{item.wallet_address}</td>
                  <td>{item.network}</td>
                  <td>{item.user_type || "-"}</td>
                  <td>
                    <Link
                      to={`/super-host-detail/${item.id}`}
                      className="super-host__detail-btn"
                    >
                      Details
                    </Link>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </section>
      <ul className="pagination">
        <li className="page-item prev2" onClick={() => handlePageClick(1)}>
          처음
        </li>
        <li
          className={`page-item prev ${page === 1 ? "disabled" : ""}`}
          onClick={() => handlePageClick(Math.max(parseInt(page) - 1, 1))}
        >
          이전
        </li>
        {pageNumbers.map((number) => (
          <li
            key={number}
            className={`page-item ${parseInt(page) === number ? "active" : ""}`}
            onClick={() => handlePageClick(number)}
          >
            {number}
          </li>
        ))}
        <li
          className="page-item next"
          onClick={() =>
            handlePageClick(Math.min(parseInt(page) + 1, totalPages))
          }
        >
          다음
        </li>
        <li
          className="page-item next2"
          onClick={() => handlePageClick(totalPages)}
        >
          끝
        </li>
      </ul>
    </div>
  );
};

export default SuperHost;
