import { useParams } from "react-router-dom";
import LadderSettings from "../components/LadderSettings";

const LadderCreate = () => {
  const initialData = [
    {
      tier_image: null,
      tier_name: "Beginner",
      min_level: 1,
      max_level: 0,
      min_exp: 0,
      max_exp: 0,
      required_exp: 0,
    },
    {
      tier_image: null,
      tier_name: "Expert",
      min_level: 0,
      max_level: 0,
      min_exp: 0,
      max_exp: 0,
      required_exp: 0,
    },
    {
      tier_image: null,
      tier_name: "Advanced",
      min_level: 0,
      max_level: 0,
      min_exp: 0,
      max_exp: 0,
      required_exp: 0,
    },
    {
      tier_image: null,
      tier_name: "Intermediate",
      min_level: 0,
      max_level: 0,
      min_exp: 0,
      max_exp: 0,
      required_exp: 0,
    },
    {
      tier_image: null,
      tier_name: "Master",
      min_level: 0,
      max_level: 0,
      min_exp: 0,
      max_exp: 0,
      required_exp: 0,
    },
  ];

  const { network } = useParams();
  //초기값을 설정해줍니다.

  return (
    <LadderSettings
      ladderSeasonData={{
        title: "",
        start_date: "",
        end_date: "",
        description: "",
        network,
      }}
      ladderPageImgData={null}
      dailyListData={[
        {
          exp_value: 0,
          experience_penalty_id: 0,
        },
      ]}
      experienceListData={[
        {
          exp_value: 0,
          experience_penalty_id: 0,
        },
      ]}
      penaltyListData={[
        {
          exp_value: 0,
          experience_penalty_id: 0,
        },
      ]}
      ladderRankData={initialData}
      ladderRankImage={[null, null, null, null, null]}
      type={"create"}
    />
  );
};

export default LadderCreate;
