import React, { useEffect, useState } from "react";
import "../styles/SuperHostDetail.scss";
import { formatLocaleTime, formatUtcTime } from "../utils/formatDate";
import { useNavigate, useParams } from "react-router-dom";

import addCircleImg from "../assets/images/add_circle.svg";
import deleteImg from "../assets/images/delete_forever.svg";
import arrowImg from "../assets/images/arrow_more_whithe.png";
import axios from "axios";
import Cookies from "js-cookie";

const serverApi = process.env.REACT_APP_AWS_API;

const SuperHostDetail = () => {
  const { host_id } = useParams();
  const [hostData, setHostData] = useState({});
  const token = Cookies.get(`token`);
  const getHostData = async () => {
    try {
      const res = await axios.get(
        `${serverApi}/api/cms/super/host/${host_id}/detail`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("getHostData", res.data);
      setHostData(res.data);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getHostData();
  }, []);

  return (
    <div className="main super-host-detail">
      <DetailManagemant hostData={hostData} host_id={host_id} />
      <DetailUserGame hostData={hostData} host_id={host_id} />
    </div>
  );
};

export default SuperHostDetail;

/**
 *
 */
const DetailManagemant = React.memo(({ hostData, host_id }) => {
  const navigate = useNavigate();
  const token = Cookies.get(`token`);
  const deleteSuperHost = async () => {
    let result = window.confirm("삭제하시겠습니까?");
    if (!result) {
      return;
    }
    try {
      const res = await axios.delete(
        `${serverApi}/api/cms/super/host/${host_id}/delete`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      alert("권한 삭제 성공");
      navigate("/super-host?page=1");
    } catch (e) {
      const error = e.response.data.detail;
      if (error === "No Condition for Deletion") {
        alert(
          "해당 계정으로 생성된 토너먼트가 존재하거나, 리그 오너의 경우 권한 삭제가 불가능합니다."
        );
      } else {
        alert("삭제 중 오류가 발생하였습니다.");
      }
      console.error(e);
    }
  };

  return (
    <section className="super-host-detail__section">
      <p className="super-host-detail__section--title">유저 관리</p>
      <div className="super-host-detail__section--box">
        <div className="super-host-detail__section--manager">
          <p className="super-host-detail__section--manager-title">등록일</p>
          <div>
            <p className="super-host-detail__secrtion--manager-content">
              {formatUtcTime(hostData?.create_dt)}
            </p>
            <p className="super-host-detail__secrtion--manager-content">
              {formatLocaleTime(hostData?.create_dt)}
            </p>
          </div>
        </div>
        <div className="super-host-detail__section--manager">
          <p className="super-host-detail__section--manager-title">닉네임</p>
          <p className="super-host-detail__secrtion--manager-content">
            {hostData?.name}
          </p>
        </div>
        <div className="super-host-detail__section--manager">
          <p className="super-host-detail__section--manager-title">지갑주소</p>
          <p className="super-host-detail__secrtion--manager-content">
            {hostData?.wallet_address}
          </p>
        </div>
        <div className="super-host-detail__section--manager">
          <p className="super-host-detail__section--manager-title">네트워크</p>
          <p className="super-host-detail__secrtion--manager-content">
            {hostData?.network}
          </p>
        </div>
        <div className="super-host-detail__section--manager">
          <p className="super-host-detail__section--manager-title">구분</p>
          <p className="super-host-detail__secrtion--manager-content">
            {hostData?.user_type}
          </p>
        </div>
        <div className="super-host-detail__section--manager-delelte">
          <p className="super-host-detail__section--manager-delete-title">
            슈퍼호스트 권한 삭제하기
          </p>
          <button
            className="super-host-detail__delete-btn"
            onClick={deleteSuperHost}
          >
            삭제
          </button>
        </div>
      </div>
    </section>
  );
});

/**
 *
 */
const DetailUserGame = React.memo(({ hostData, host_id }) => {
  const navigate = useNavigate();
  const token = Cookies.get(`token`);
  const [dropdown, setDropdown] = useState(null);
  const [dropdownGameList, setDropdownGameList] = useState([]);
  const [permissionArray, setPermissionArray] = useState(null);

  const addPermissionArray = () => {
    let copy = [...permissionArray];
    copy.push({
      game_name: "",
      game_id: null,
    });
    setPermissionArray(copy);
  };

  const removePermissionArray = (index) => {
    let copy = [...permissionArray];
    if (copy.length === 1) return;
    copy.splice(index, 1);
    setPermissionArray(copy);
  };

  const selectPermission = (index, item) => {
    let copy = [...permissionArray];
    copy[index].game_name = item.name;
    copy[index].game_id = item.pk;
    setPermissionArray(copy);
  };

  //===================
  // 게임 리스트 목록 저장
  //===================
  const submit = async () => {
    let result = window.confirm("수정하시겠습니까?");
    if (!result) {
      return;
    }
    let copy = permissionArray.filter((item) => item.game_id);
    let ids = copy.map((item) => item.game_id);
    let setId = Array.from(new Set(ids));

    let requestDataJson = JSON.stringify({
      limit_game_id_list: setId,
    });

    try {
      const res = await axios.post(
        `${serverApi}/api/cms/super/host/${host_id}/limit/game/list/`,
        requestDataJson,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      navigate("/super-host?page=1");
    } catch (e) {
      alert("게임 권한 수정 중 오류가 발생하였습니다.");
      console.error(e);
    }
  };

  //===================
  // 게임 리스트 목록 가져오기
  //===================
  const getGameList = async () => {
    try {
      const res = await axios.get(
        `${serverApi}/api/cms/limit/game/list/?network=${hostData?.network}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setDropdownGameList(res.data);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (!hostData?.game_list || permissionArray !== null) {
      return;
    }
    let array;
    if (hostData?.game_list.length === 0) {
      array = [
        {
          game_name: "",
          game_id: "",
        },
      ];
    } else {
      array = hostData?.game_list;
    }
    setPermissionArray(array);
  }, [hostData?.game_list]);

  useEffect(() => {
    if (!hostData?.network) {
      return;
    }
    getGameList();
  }, [hostData?.network]);

  return (
    <section className="super-host-detail__section">
      <p className="super-host-detail__section--title">게임 수정</p>
      <div className="super-host-detail__section--box">
        <p>게임 권한</p>
        <div className="super-host-detail__section--game">
          <div className="super-host-detail__section--game-description">
            <span className="super-host-detail__section--game-description-number">
              숫자
            </span>
            <span className="super-host-detail__section--game-description-name">
              게임 이름
            </span>
          </div>
          {permissionArray?.map((item, index) => (
            <article
              className="super-host-detail__section--game-select"
              key={index}
            >
              <div className="super-host-detail__section--game-content">
                <div className="game-index">{index + 1}</div>
                <div
                  className="game-name__box"
                  onClick={(e) => {
                    setDropdown((prev) => {
                      if (prev === index) return null;
                      else return index;
                    });
                  }}
                >
                  {item.game_name || (
                    <p className="game-name__box--unselect">선택해주세요</p>
                  )}
                  <img
                    className={`game-name__box--dropdown-arrow ${
                      dropdown === index && "active-img"
                    }`}
                    src={arrowImg}
                  />
                  {/**드롭 다운 콘텐츠 */}
                  <ul
                    className={`game-name__box-dropdown ${
                      dropdown === index && "active"
                    }`}
                  >
                    {dropdownGameList.length === 0 &&
                      "제약 설정 가능한 게임이 존재하지 않습니다."}
                    {dropdownGameList.map((item, i) => (
                      <li
                        className="game-name__box-dropdown--content"
                        key={i}
                        onClick={() => selectPermission(index, item)}
                      >
                        {item.name}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <img
                src={deleteImg}
                onClick={() => removePermissionArray(index)}
              ></img>
            </article>
          ))}
        </div>
        <button
          className="super-host-detail__section--game-add-btn"
          onClick={addPermissionArray}
        >
          <img src={addCircleImg} />
          추가하기
        </button>
      </div>
      <button
        className="super-host-detail__section--permission"
        onClick={submit}
      >
        게임 수정 완료
      </button>
    </section>
  );
});
