import "../styles/Ladder.scss";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Cookies from "js-cookie";
// 이미지
import searchIcon from "../assets/images/searchIcon.png";
import checkImg from "../assets/images/check.png";
import addBtn from "../assets/images/add_box.svg";

import DeleteModal from "../components/DeleteModal";
import TokenType from "../components/TokenType";
import GovernanceCancelModal from "../components/GovernanceCancelModal";
import { formatUtcTime, formatLocaleTime } from "../utils/formatDate";

function Ladder() {
  const serverApi = process.env.REACT_APP_AWS_API;

  const { network } = useParams();
  const [queryParams, setQueryParams] = useSearchParams();
  const page = queryParams.get("page");
  const searchKeyword = queryParams.get("search_keyword");

  const [ladderList, setLadderList] = useState([]);

  // 페이지네이션
  const pagesPerBlock = 5;
  const [pageNumbers, setPageNumbers] = useState([]);
  const [totalPages, setTotalPages] = useState(0);

  const getLadderList = async () => {
    try {
      const res = await axios.get(
        `${serverApi}/api/cms/ladder/season/main/list?network=${network}&page=${page}&search_keyword=${
          searchKeyword || ""
        }`
      );

      setTotalPages(Math.ceil(res.data.total_cnt / 6));
      setLadderList(res.data.data_list);
    } catch (e) {
      console.error(e);
    }
  };

  const handlePageClick = (page) => {
    setQueryParams({ page: page, search_keyword: searchKeyword });
  };

  useEffect(() => {
    getLadderList();
  }, [network, page, searchKeyword]);

  useEffect(() => {
    const startPage =
      Math.floor((page - 1) / pagesPerBlock) * pagesPerBlock + 1;
    const newPageNumbers = Array.from(
      { length: pagesPerBlock },
      (_, i) => startPage + i
    ).filter((page) => page <= totalPages);
    setPageNumbers(newPageNumbers);
  }, [page, totalPages]);

  return (
    <>
      <div className="main ladder">
        <div className="main--inner">
          <div className="header--box">
            <div className="main--title">
              레더시즌 관리
              <TokenType tokenType={network} />
            </div>
            <div className="create-box">
              <Link
                to={`/ladder-create/${network}`}
                className="create-box__btn"
              >
                <img src={addBtn} />
                생성
              </Link>
              <div className="search--box">
                <input
                  type="text"
                  placeholder="Type here..."
                  onKeyPress={(e) => {
                    if (e.key === "Enter")
                      setQueryParams({
                        page: 1,
                        search_keyword: e.target.value,
                      });
                  }}
                />
                <button className="search--btn">
                  <img src={searchIcon} alt="searchIcon" />
                </button>
              </div>
            </div>
          </div>
          {/* header--box --END--*/}
          <div className="ladder--list--box">
            <table>
              <thead>
                <tr>
                  <th>레더 번호</th>
                  <th>레더 시즌명</th>
                  {/* <th>게임명</th> */}
                  <th>시작일</th>
                  <th>종료일</th>
                  <th>레더 상태</th>
                  <th>상세</th>
                  <th>수정</th>
                </tr>
              </thead>
              <tbody>
                {ladderList.length > 0 &&
                  ladderList?.map((item) => (
                    <tr key={item.id}>
                      <td>{item.id}</td>
                      <td>{item.title}</td>
                      {/* <td>게임 이름</td> */}
                      <td>
                        {formatUtcTime(item?.start_date)}
                        <br />
                        {formatLocaleTime(item?.start_date)}
                      </td>
                      <td>
                        {formatUtcTime(item?.end_date)}
                        <br />
                        {formatLocaleTime(item?.end_date)}
                      </td>
                      <td>
                        <p
                          key={item.id}
                          className={
                            item.status === "In Progress"
                              ? "in-progress"
                              : item.status === "END"
                              ? "end"
                              : ""
                          }
                        >
                          {item.status}
                        </p>
                      </td>
                      <td>
                        <Link
                          to={`/ladder-edit/${network}/${item.id}`}
                          className="link--btn"
                        >
                          Details
                        </Link>
                      </td>
                      <td>
                        <Link
                          to={`/ladder-edit/${network}/${item.id}`}
                          className="edit"
                        >
                          Edit
                        </Link>
                      </td>
                    </tr>
                  ))}
                {ladderList.length === 0 && (
                  <tr>
                    <td colSpan="7">데이터가 없습니다.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <ul className="pagination">
            <li className="page-item prev2" onClick={() => handlePageClick(1)}>
              처음
            </li>
            <li
              className={`page-item prev ${
                parseInt(page) === 1 ? "disabled" : ""
              }`}
              onClick={() => handlePageClick(Math.max(page - 1, 1))}
            >
              이전
            </li>
            {pageNumbers.map((number) => (
              <li
                key={number}
                className={`page-item ${
                  parseInt(page) === number ? "active" : ""
                }`}
                onClick={() => handlePageClick(number)}
              >
                {number}
              </li>
            ))}
            <li
              className="page-item next"
              onClick={() =>
                handlePageClick(Math.min(parseInt(page) + 1, totalPages))
              }
            >
              다음
            </li>
            <li
              className="page-item next2"
              onClick={() => handlePageClick(totalPages)}
            >
              끝
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default Ladder;
