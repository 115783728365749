import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./LoadingComponent.scss";
import loadingImg from "../assets/images/loadingImg.gif";

const LoadingComponent = () => {


  return (
    <>
      <div className="loading--component">
        <img src={loadingImg} alt="loadingImg"/>
      </div>
    </>
  );
};

export default LoadingComponent;
