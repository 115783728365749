// 로컬 타임 포메팅
export const formatLocaleTime = (time) => {
  if (!time) {
    time = new Date().toISOString().slice(0, 19);
  }

  const date = new Date(`${time}+09:00`);
  const weekday = date.toLocaleString("en-US", { weekday: "short" });
  const month = date.toLocaleString("en-US", { month: "short" });
  const day = date.getDate().toString().padStart(2, "0");
  const year = date.getFullYear();
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = date.getSeconds().toString().padStart(2, "0");
  const offsetInMinutes = new Date().getTimezoneOffset();
  const offsetHours = -offsetInMinutes / 60;
  const offsetMinutes = -offsetInMinutes % 60 > 0 ? -offsetInMinutes % 60 : "";
  const prefix = offsetHours > 0 ? "+" : "-";
  const infix = offsetMinutes > 0 ? ":" : "";

  return `${weekday}, ${day} ${month} ${year} ${hours}:${minutes}:${seconds} UTC${prefix + Math.floor(Math.abs(offsetHours)) + infix + offsetMinutes}`;
};

// UTC 타임 포메팅
export const formatUtcTime = (time) => {
  if (!time) {
    time = new Date().toISOString().slice(0, 19);
  }

  const date = new Date(`${time}+09:00`);
  const utcDate = new Date(date.toUTCString());

  const weekday = utcDate.toLocaleString("en-US", { weekday: "short" });
  const month = utcDate.toLocaleString("en-US", { month: "short" });
  const day = utcDate.getUTCDate().toString().padStart(2, "0");
  const year = utcDate.getUTCFullYear();
  const hours = utcDate.getUTCHours().toString().padStart(2, "0");
  const minutes = utcDate.getUTCMinutes().toString().padStart(2, "0");
  const seconds = utcDate.getUTCSeconds().toString().padStart(2, "0");

  return `${weekday}, ${day} ${month} ${year} ${hours}:${minutes}:${seconds} UTC+0`;
};

// 년 월 일 포맷팅 (예: August 14, 2024)
export const formatYMD = (time) => {
  const timeOption = {
    month: "long",
    day: "numeric",
    year: "numeric",
  };
  return new Date(`${time}+09:00`).toLocaleDateString("en-US", timeOption);
};

export const formatDate = (time) => {
  let date = new Date(time);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // 월은 0부터 시작하므로 +1
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};
